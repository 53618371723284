<script>
import Layout from "@/router/layouts/main";
import { mapState, mapActions } from "vuex";
import Avatar from "@/components/widgets/avatar";
import { required, email, minLength } from "vuelidate/lib/validators";
import { checkUsername } from "@/state/validations/register";
import { api } from "@/state/services";
export default {
  locales: {
    pt: {
      'Account': 'Conta',
      'Username': 'Usuário',
      'Name': 'Nome',
      'Cellphone': 'Celular',
      'Password': 'Senha',
      "Update Cellphone": 'Atualizar Celular',
    },
    es: {
      'Account': 'Cuenta',
      'Username': 'Nombre de Usuario',
      'Name': 'Nombre',
      'Cellphone': 'Celular',
      'Update Cellphone': 'Actualizar Celular'
    }
  },
  components: {
    Layout,
    Avatar
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert,
    }),

    codeUppercase: {
      get() {
        return this.code.toUpperCase();
      },
      set(value) {
        this.code = value.toUpperCase();
      }
    },
  },
  validations: {
    user: {
      cellphone: { required },
      name: { required },
      email: { required, email },
      username: { required, checkUsername, minLength: minLength(4) },
    }

  },

  data() {
    return {

      loading: {
        register: false,
      },
      code: "",
      cellphone: "",
      user: {
        username: "",
        name: "",
      },
      modal: false,
      success: false,
      country: {
        abbr: "BR",
        code: "55",
        name: "Brazil",
        mask: "+55.###############",
      },
      countries: {},
      whatsapp: {
        status: 'empty',
      },
    };
  },
  methods: {
    ...mapActions("account", ["updateUser"]),
    handleChange(event) {
      this.country = this.countries[event.target.value];
      this.country.mask = "###############";
    },
    getCountries() {
      api.get("country").then((response) => {
        if (response.data.status == "success") {
          this.countries = response.data.list;
          var ddi = this.cellphone.split('.')[0];
          var result = Object.entries(this.countries);
          result.forEach(element => {
            if (element[1].code == ddi.replace('+', '')) {
              this.country = this.countries[element[0]];
              this.country.mask = "###############";
              this.$set(this.user, 'country', element[0]);
            }
          });

        }
      });
    },
    sendCode() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { cellphone } = this.user;
        if (cellphone && this.country) {
          this.loading.register = true;
          api
            .get("user/token")
            .then((response) => {
              if (response.data.status == "success") {
                this.modal = true;
                this.success = true;
              } else {
                this.alert.type = "alert-danger";
                this.alert.message = response.data.message;
              }

              this.loading.register = false;
            });
        }
      }
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { name, cellphone, username } = this.user;
        if (name && cellphone && username && this.code) {
          this.loading.register = true;
          api
            .post("user/update", {
              name: this.user.name,
              code: this.code,
              cellphone: "+" + this.country.code + "." + this.user.cellphone,
              username: this.user.username

            })
            .then((response) => {
              if (response.data.status == "success") {

                this.alert.type = "alert-success";
                this.alert.message = response.data.message;
                this.modal = false;
                this.success = true;
                this.code = '';
                this.updateUser();
              } else {
                this.alert.type = "alert-danger";
                this.alert.messageModal = response.data.message;
              }

              this.loading.register = false;
            });
        }
      }
    },
    checkWhatsapp() {
      api.post("whatsapp/check", {
        number: this.user.cellphone
      }).then((response) => {
        this.whatsapp.status = response.data.status;
      });
    }
  },
  mounted() {
    let objUser = Object.assign('', this.account.user);
    this.cellphone = objUser.cellphone;
    let tel = this.cellphone.split('.')[1];
    this.user.username = objUser.username;
    this.user.name = objUser.name;
    this.user.cellphone = tel;
    this.user.email = objUser.email.address;
    this.getCountries();

  },

};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Account') }}</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <Avatar></Avatar>
              </div>
              <div class="col-md-8 pt-md-5">
                <div v-if="alert.message" :class="'alert ' + alert.type">
                  {{ t(alert.message) }}
                </div>
                <div class="row pt-3 pb-0 pl-3 pr-3">
                  <div class="col-md-6 text-center-sm">
                    <b-form-group id="username-group" :label="t('Username')" label-for="username">
                      <b-form-input id="username" v-model="user.username" type="text" :class="{ 'is-invalid': $v.user.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"></b-form-input>
                      <div v-if="!$v.user.username.required" class="invalid-feedback">
                        {{ t("Username is required.") }}
                      </div>
                      <div v-if="$v.user.username && !$v.user.username.checkUsername" class="invalid-feedback">
                        {{ t("Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.") }}
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 text-center-sm">
                    <b-form-group id="name-group" :label="t('Name')" label-for="name">
                      <b-form-input id="name" v-model="user.name" type="text" :class="{ 'is-invalid': $v.user.name.$error }"></b-form-input>
                      <div v-if="!$v.user.name.required" class="invalid-feedback">
                        {{ t("Name is required.") }}
                      </div>
                    </b-form-group>

                  </div>
                  <div class="col-md-6 text-center-sm">
                    <b-form-group id="email-group" :label="t('E-mail')" label-for="email">
                      <b-form-input id="email" style="background:#d1d1d1" disabled v-model="user.email" type="email" :class="{ 'is-invalid': $v.user.email.$error }"></b-form-input>
                      <div v-if="$v.user.email.$error" class="invalid-feedback">
                        <span v-if="!$v.user.email.required">{{ t("E-mail is required.") }}</span>
                        <span v-if="!$v.user.email.email">{{ t("This value should be a valid e-mail.") }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 text-center-sm">
                    <b-form-group id="cellphone-group" :label="t('Cellphone')" label-for="cellphone">
                      <select v-model="user.country" class="custom-select mb-1" @change="handleChange">
                        <option v-for="(option, index) in countries" :key="index" :value="option.abbr" :selected="option.abbr == country.abbr">
                          {{ option.abbr + " " + option.name + " +" + option.code }}
                        </option>
                      </select>

                      <b-input-group :prepend="country.abbr + ' +' + country.code" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="cellphone" v-model="user.cellphone" type="text" :class="{ 'is-invalid': $v.user.cellphone.$error }" v-mask="country.mask"
                          v-on:blur="checkWhatsapp()"></b-form-input>
                      </b-input-group>
                      <div v-if="!$v.user.cellphone.required" class="invalid-feedback">
                        {{ t("Cellphone is required.") }}
                      </div>
                      <div v-if="whatsapp.status == 'inactive'" class="invalid-feedback d-block text-warning d-flex pt-2 px-2 align-items-center">
                        <div class="mr-2">
                          <i class="lnr lnr-warning font-size-24"></i>
                        </div>
                        <div class="font-size-12">
                          <strong>Atenção: </strong>Informe sempre um número válido de Whatsapp para que os doadores
                          entrem em contato com você.
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 offset-md-6 text-center-sm">
                    <b-button :disabled="loading.register == true
                      " type="button" v-on:click="sendCode" variant="default" class="btn-lg btn-block">
                      {{ t("Edit") }}
                      <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modal" :title="t('Update Account')" centered>
      <div class="text-center">
        <div v-if="alert.messageModal" :class="'alert ' + alert.type">
          {{ t(alert.messageModal) }}
        </div>
        <div class="row justify-content-center mb-3">
          <h5 class="col-md-10">{{ t("For your security, a request for data changes will be sent to your email. Check to give permission.") }}</h5>
        </div>
        <div class="mb-3">
          <img class="rounded-circle header-profile-user" src="@/assets/images/logo-icon.png" alt="" style="width: 100px; height: 100px" />
        </div>
        <div class="font-size-16 notranslate">
          <b-form @submit.prevent="registerSubmit">
            <b-form-group id="code-group-1" :label="t('Code')" label-for="code">
              <b-form-input id="code" maxlength="6" v-model="codeUppercase" type="text" required></b-form-input>
            </b-form-group>

            <b-button :disabled="loading.register == true
              " type="submit" variant="default" class="btn-lg btn-block">
              {{ t("Send") }}
              <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
          </b-form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
#code {
  text-align: center;
}
</style>